<template>
  <div>
    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="BoxIcon" />
          <span>Makine</span>
        </template>
        <machine-search />
      </b-tab>
      <b-tab
        :disabled="!formData.id_machines"
      >
        <template #title>
          <feather-icon icon="FileTextIcon" />
          <span>Servis</span>
        </template>
        <service-form1 v-if="formType === '1'" />
        <service-form2 v-if="formType === '2'" />
      </b-tab>
      <b-tab :disabled="!formData.id_machines">
        <template #title>
          <feather-icon icon="ImageIcon" />
          <span>Fotoğraf</span>
        </template>
        <photo-upload />
      </b-tab>
      <b-tab :disabled="!formData.id_machines">
        <template #title>
          <feather-icon icon="EyeIcon" />
          <span>Önizleme & Kayıt</span>
        </template>
        <b-card>
          <b-card-title>Müşteri Kartı</b-card-title>
          <b-card-text>
            <customer-card />
          </b-card-text>
        </b-card>
        <b-card>
          <b-card-title>Makine Kartı</b-card-title>
          <b-card-text>
            <machine-card />
          </b-card-text>
        </b-card>
        <b-card>
          <b-card-title>Servis Kartı</b-card-title>
          <b-card-text>
            <service-card
              :service-data="formData"
              :form-type="formType"
            />
            <hr>
            <div class="mt-2">
              <b-form-group>
                <b-form-checkbox
                  v-model="sendApproval"
                  name="check-button"
                  switch
                  inline
                >
                  <span class="text-danger">
                    Yetkili Onayına Gönderilsin
                  </span>
                </b-form-checkbox>
              </b-form-group>
              <b-alert
                v-if="formData.id_service_statuses !== 1"
                show
                variant="warning"
              >
                <div class="alert-body text-center">
                  <FeatherIcon icon="InfoIcon" />
                  Servis formu onay yetkisine gönderilecektir. Bu işlem geri alınamaz.
                </div>
              </b-alert>
            </div>
          </b-card-text>
        </b-card>
        <div class="text-center">
          <b-button
            variant="primary"
            :disabled="submitStatus || saveStatus.status"
            @click="submitForm"
          >
            <FeatherIcon icon="CheckCircleIcon" />
            {{ (formData.id_service_statuses !== 1)? 'Onaya Gönder' : 'Kaydet' }}
          </b-button>
        </div>
      </b-tab>
    </b-tabs>
    <b-modal
      id="qrcode-modal"
      centered
      title="QR Kod Okuyucu"
      size="lg"
      ok-only
      ok-title="Kapat"
    >
      <b-card-text>
        QR Kod Okuyucu Buraya Gelecek.
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardText,
  BFormGroup,
  BButton,
  BTabs,
  BTab,
  BModal,
  BFormCheckbox,
  BAlert,
} from 'bootstrap-vue'
import MachineSearch from '@/views/Services/Components/MachineSearch.vue'
import ServiceForm1 from '@/views/Services/Forms/ServiceForm1.vue'
import ServiceForm2 from '@/views/Services/Forms/ServiceForm2.vue'
import CustomerCard from '@/views/Services/Components/CustomerCard.vue'
import MachineCard from '@/views/Services/Components/MachineCard.vue'
import ServiceCard from '@/views/Services/Components/ServiceCard.vue'
import PhotoUpload from '@/views/Services/Forms/PhotoUpload.vue'

export default {
  name: 'Add',
  components: {
    BCard,
    BCardTitle,
    BCardText,
    BFormGroup,
    BButton,
    BTabs,
    BTab,
    BModal,
    BFormCheckbox,
    BAlert,
    MachineSearch,
    ServiceForm1,
    ServiceForm2,
    CustomerCard,
    MachineCard,
    ServiceCard,
    PhotoUpload,
  },
  data() {
    return {
      submitStatus: false,
      machineKeyword: null,
      machineSelected: false,
      sendApproval: false,
      formType: this.$route.path.split('/')[3],
    }
  },
  computed: {
    formData() {
      return this.$store.getters['services/getService']
    },
    saveStatus() {
      return this.$store.getters['services/getServiceSaveStatus']
    },
  },
  watch: {
    formData: {
      deep: true,
      handler(val) {
        if (val.refractometer) {
          this.formData.concentration = val.refractometer * this.formData.refVal
        }
      },
    },
    sendApproval(val) {
      if (this.formData.id_service_statuses !== 3) {
        if (val === true) {
          this.formData.id_service_statuses = 2
        } else {
          this.formData.id_service_statuses = 1
        }
      }
    },
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: 'Kayıt işleminiz başarılı!',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: 'Kayıt işleminiz yapılamadı.! Lütfen tekrar deneyiniz.',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.$store.commit('services/RESET_DATA')
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.submitStatus = true
      this.formData.formType = this.formType
      this.$store.dispatch('services/serviceSave', this.formData)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
